import { FormatOptions } from './format.types'

interface FormatStorage {
  get: () => FormatOptions | undefined
  set: (format: FormatOptions) => void
  run: <T>(format: FormatOptions, callback: () => T) => T
}

let formatStorage: FormatStorage

if (typeof window === 'undefined') {
  // Node.js environment
  const { AsyncLocalStorage } = require('async_hooks')
  const asyncLocalStorage = new AsyncLocalStorage()

  formatStorage = {
    get: () => asyncLocalStorage.getStore(),
    set: (format: FormatOptions) => asyncLocalStorage.enterWith(format),
    run: (format: FormatOptions, callback: () => any) =>
      asyncLocalStorage.run(format, callback),
  }
} else {
  // Browser environment
  let currentFormat: FormatOptions | undefined

  formatStorage = {
    get: () => currentFormat,
    set: (format: FormatOptions) => {
      currentFormat = format
    },
    run: (format: FormatOptions, callback: () => any) => {
      const previousFormat = currentFormat
      currentFormat = format
      try {
        return callback()
      } finally {
        currentFormat = previousFormat
      }
    },
  }
}

export { formatStorage }
