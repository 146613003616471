import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isoWeek from 'dayjs/plugin/isoWeek'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isToday from 'dayjs/plugin/isToday'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { default as minmax, default as minMax } from 'dayjs/plugin/minMax'
import relativeTime from 'dayjs/plugin/relativeTime'
import timeZone from 'dayjs/plugin/timezone'
import toArray from 'dayjs/plugin/toArray'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import weekOfYear from 'dayjs/plugin/weekOfYear'
// each locale file is about 3.8k. Perhaps we should only import dynamically when needed
import './locales'
import { timezoneStorage } from './timezoneStorage'
import { formatStorage } from './formatStorage'
import type { FormatOptions } from './format.types'

// const dayjsExt = dayjs;
dayjs.extend(localeData)
dayjs.extend(utc)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(customParseFormat)
dayjs.extend(weekday)
dayjs.extend(isBetween)
dayjs.extend(isToday)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(timeZone)
dayjs.extend(toArray)
dayjs.extend(minmax)
dayjs.extend(duration)
dayjs.extend(minMax)
dayjs.extend(isoWeek)
dayjs.extend(quarterOfYear)
dayjs.extend(weekOfYear)

export * from './businessTime'
export * from './daysInMonth'
export * from './dst'
export * from './duration'
export * from './essentialTimezones'
export * from './format'
export * from './recurringEvent'
export * from './weekday'
export * from './set'
export * from './intersection'

export type Dayjs = dayjs.Dayjs

export type { ConfigType } from 'dayjs'

function customDayjs(...args: Parameters<typeof dayjs>): dayjs.Dayjs {
  const timezone = timezoneStorage.get()
  if (timezone) {
    return dayjs(...args).tz(timezone)
  }
  return dayjs(...args)
}

// Copy all properties from original dayjs
Object.assign(customDayjs, dayjs)

customDayjs.tz = dayjs.tz
customDayjs.utc = dayjs.utc
customDayjs.locale = dayjs.locale
customDayjs.duration = dayjs.duration
customDayjs.max = dayjs.max
customDayjs.min = dayjs.min
customDayjs.unix = dayjs.unix

export { timezoneStorage, formatStorage, FormatOptions }
export default customDayjs
